import React from 'react'
import './style.css'
import { List, Icon } from 'antd'
import SimpleBadge from '../Badge/SimpleBadge'

//onClick defisice se l'Item è clickable
//collapsed definisce se è di tipo collapsed
//Item.Title definisce il titolo
//Item.Subtitle definisce il sottotitolo

class SimpleList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isCollapsed: false,
            dataSource: this.props.dataSource,
            selected: this.props.selected
        }
    }

    getDataSource() {
        return this.state.isCollapsed ? this.props.dataSource.filter(item => item.key === this.state.selected) : this.props.dataSource
    }

    render() {
        return (
            <div className={"simplelist-container"}>
                {"title" in this.props && <h3 style={{ margin: '16px 0' }}>{this.props.title}</h3>}
                <List {...this.props}
                    dataSource={this.getDataSource()}
                    size="large"
                    bordered
                    renderItem={item => {
                        return (
                            <List.Item className={`listitem ${this.state.selected === item.key ? "selected" : ""} ${this.props.onClicked ? "clickable" : ""}`}
                                onClick={() => {
                                    this.props.onClicked && this.setState({ selected: item.key })
                                    this.state.isCollapsed && this.setState({ selected: null })
                                    this.props.collapsed && this.setState({ isCollapsed: !this.state.isCollapsed })

                                    return this.props.onClicked((this.props.collapsed && this.state.isCollapsed) ? null : item.key)
                                }}
                                >
                                <div className={"line-container"}>
                                    <div className={"text-container"}>
                                        <div className={"text-title"}>{item.title}</div>
                                        <div className={"text-subtitle"}>{item.subtitle}</div>
                                    </div>
                                    <div className={"icons-container"}>
                                        {item.badge && <SimpleBadge loading={item.badgeLoading ? true : false} count={item.badgeCount} />}
                                        <Icon className={"icon"} type={this.state.isCollapsed ? "down" : "right"} />
                                    </div>
                                </div>
                            </List.Item>
                        )
                    }}
                />
            </div>

        )
    }

}

export default SimpleList