import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../../react-auth0-spa";
// import { COOKIE_ACCESS_TOKEN } from "../../const/globalConsts";

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    // console.log("...in privateroute useAuth0")
    // console.log("...loading", loading)
    // console.log("...isAuthenticated", isAuthenticated)
    // console.log("...loginWithRedirect", loginWithRedirect)
    // console.log("...accessToken", localStorage.getItem(COOKIE_ACCESS_TOKEN))

    var wrapLoginWithRedirect = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      });
    }; 

    !isAuthenticated && wrapLoginWithRedirect();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props => isAuthenticated && <Component {...props} />

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;