import React from 'react';
import './index.css'
import App from './containers/App/App';
import { ThemeProvider } from "styled-components";
import store from './redux/store'
import { Auth0Provider } from "./react-auth0-spa";
import history from "./utils/history";
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack';
import { Router } from "react-router-dom";
import themes from "./settings/themes";
import { themeConfig } from "./settings";
import AntDStyle from "./settings/antd.style";
import { ga } from 'react-ga'

//Forced redirect from http to https
if (window.location.hostname !== "localhost" && window.location.protocol !== 'https:') {
    window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    history.push(
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

history.listen((location) => {
    ga('set', 'page', location.pathname + location.search);
    ga('send', 'pageview');
});

const auth0config = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    client_id: process.env.REACT_APP_AUTH0_CLIENTID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE
}

const DashApp = () => {
    console.log("Run with process.env", process.env)
    return (<ThemeProvider theme={themes[themeConfig.theme]}>
        <Auth0Provider
            domain={auth0config.domain}
            client_id={auth0config.client_id}
            redirect_uri={window.location.origin}
            audience={auth0config.audience}
            onRedirectCallback={onRedirectCallback}
        >
            <Provider store={store}>
                <SnackbarProvider maxSnack={3}>
                    <Router history={history}>
                        <AntDStyle>
                            <App />
                        </AntDStyle>
                    </Router>
                </SnackbarProvider>
            </Provider>
        </Auth0Provider>
    </ThemeProvider>)
}

export default DashApp;
