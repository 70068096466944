import {
    IS_LOGGED,
    SET_ACCESS_TOKEN,
    SET_REFRESH_TOKEN,
    SET_EMAIL,
    SET_IS_LOADING,
    SET_URL,
    SET_IS_LOGGED
} from './../../const/actionType'

const INITIAL_STATE = { 
    isLogged:false,
    isLoading: false,
    access_token: "",
    refresh_token: "",
    currentURL: ""
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type){
        case SET_ACCESS_TOKEN:{
            console.log("in reducer setto access token", action.payload)
            return { ...state, access_token: action.payload };
        }
        case SET_REFRESH_TOKEN:{
            console.log("in reducer setto refresh token", action.payload)
            return { ...state, refresh_token: action.payload };
        }
        case SET_EMAIL: {
            console.log("in reducer setto Email: ", action.payload)
            return { ...state, email: action.payload}
        }
        case SET_IS_LOADING: {
            console.log("in reducer setto isLoading: ", action.payload)
            return { ...state, isLoading: action.payload}
        }
        case SET_URL: {
            console.log("in reducer setto url: ", action.payload)
            return { ...state, currentURL: action.payload}
        }
        case SET_IS_LOGGED: {
            console.log("in reducer setto isLogged: ", action.payload)
            return { ...state, isLogged: action.payload}
        }
        default:
            return state;
    }
}