import React, { Component } from 'react';
import { connect } from 'react-redux';
import { actGlobalFetch, getStatusCode } from '../../../../redux/api/actions';
import { withSnackbar } from 'notistack';
import { Typography, Icon } from 'antd'
import SimpleList from '../../../../Components/List/SimpleList'
import ListRefertos from './ListRefertos'
import './style.css'
import { URLListChampionships, URLRefertoCounterByCompetition, URLRefertoCounterByRound, SERVICES, METHOD, BODY_TYPE } from '../../../../core-trainer/assets/consts/api';
import { COOKIE_LIMITATIONS } from '../../../../const/globalConsts';


class RefertiGaraList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listChampionship: [],
            championshipSelected: null,
            roundSelected: null,
            isDownloadingData: false,
            countObjects: {}
        }
    }

    componentDidMount() {
        document.title = "Referti Gara"
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ isDownloadingData: true })
        const _limitations = JSON.parse(localStorage.getItem(COOKIE_LIMITATIONS))
        this.props.actGlobalFetch(
            URLListChampionships(_limitations),
            SERVICES.BASE_CORE,
            METHOD.GET,
            false,
            BODY_TYPE.EMPTY
        ).then(response => {
            if (getStatusCode(response) === 200) {
                // Fix veloce per filtrare la lista campionati
                if (_limitations[0].type == 'region') response.data = response.data.filter(_ => _.delegationProvince === null)
                // Filtro campionati per teamTemplate
                response.data = response.data.filter(_ => (_.teamTemplate.id >= 1 && _.teamTemplate.id <= 10) || (_.teamTemplate.id >= 32 && _.teamTemplate.id <= 42))
                this.setState({ listChampionship: response.data })
            } else {
                this.showError()
            }
            this.setState({ isDownloadingData: false })
        }).catch((err) => {
            this.showError(err)
            this.setState({ isDownloadingData: false })
        })
    }

    setChampionshipCount(idChampionship, loading, count) {
        var _countObjects = this.state.countObjects
        if (!([idChampionship] in _countObjects)) _countObjects[idChampionship] = {}
        _countObjects[idChampionship].loading = loading
        _countObjects[idChampionship].count = count
        this.setState({ countObjects: _countObjects })
    }

    setRoundCount(idChampionship, idRound, loading, count) {
        var _countObjects = this.state.countObjects
        _countObjects[idChampionship][idRound] = { loading: loading, count: count }
        this.setState({ countObjects: _countObjects })
    }

    fetchCountChampionship = async (idChampionship) => {
        this.setChampionshipCount(idChampionship, true, "")
        this.props.actGlobalFetch(URLRefertoCounterByCompetition(idChampionship), SERVICES.BASE_MATCH, METHOD.GET, true, BODY_TYPE.EMPTY)
            .then(response => {
                if (getStatusCode(response) == 200) {
                    this.setChampionshipCount(idChampionship, false, response.data.count)
                } else {
                    this.showError()
                }
            })
            .catch((err) => {
                this.showError(err)
            })
    }

    fetchCountRound = (idChampionship, idRound) => {
        this.setRoundCount(idChampionship, idRound, true, "")
        this.props.actGlobalFetch(URLRefertoCounterByRound(idChampionship, idRound), SERVICES.BASE_MATCH, METHOD.GET, true, BODY_TYPE.EMPTY)
            .then(response => {
                if (getStatusCode(response) == 200) {
                    this.setRoundCount(idChampionship, idRound, false, response.data.count)
                } else {
                    this.showError()
                }
            })
            .catch((err) => {
                this.showError(err)
            })
    }

    showError(e = null) {
        console.log("[ERROR]", e)
        this.props.enqueueSnackbar("Ops, qualcosa è andato storto", {
            variant: "error"
        });
    }


    render() {
        return (
            this.state.isDownloadingData ?
                <div>
                    <Typography>Download Campionati...</Typography>
                    <Icon type="loading" />
                </div>
                :
                <div className="referti-gara-container">
                    <div className="left-section-container">
                        <div className="list-container" data-cy="referto-category-list">
                            <SimpleList
                                title={"Scegli la categoria"}
                                dataSource={this.state.listChampionship.map(
                                    item => {
                                        const catCountObj = this.state.countObjects
                                        const isCountPresent = [item.id] in catCountObj
                                        const category = item.teamTemplate.name
                                        const division = item.teamTemplate.teamDivision.name
                                        const zone = division === "Provinciale" ? item.delegation.name : division === "Regionale" ? item.region.name : division === "Nazionale" ? item.country.name : ""
                                        !isCountPresent && this.fetchCountChampionship(item.id)
                                        return (
                                            {
                                                key: item.id,
                                                title: `${category} ${zone}`,
                                                badge: true,
                                                badgeLoading: catCountObj[item.id].loading,
                                                badgeCount: catCountObj[item.id].count
                                            }
                                        )
                                    })}
                                onClicked={(item) => {
                                    this.setState({ championshipSelected: item, roundSelected: null })
                                }}
                                selected={this.state.championshipSelected}
                                collapsed
                            />
                        </div>
                        <div className="list-container" data-cy="referto-round-list">
                            {this.state.championshipSelected && <SimpleList
                                title={"Scegli il girone"}
                                dataSource={this.state.listChampionship.find(obj => obj.id === this.state.championshipSelected)
                                    .rounds.map(
                                        round => {
                                            const championshipSelected = this.state.championshipSelected
                                            const roundCountObj = this.state.countObjects[championshipSelected]
                                            const roundId = round.roundId
                                            const isCountPresent = [roundId] in roundCountObj
                                            !isCountPresent && this.fetchCountRound(championshipSelected, roundId)
                                            return (
                                                {
                                                    key: roundId,
                                                    title: round.name,
                                                    badge: true,
                                                    badgeLoading: roundCountObj[roundId].loading,
                                                    badgeCount: roundCountObj[roundId].count
                                                }
                                            )
                                        }
                                    )}
                                onClicked={(item) => { this.setState({ roundSelected: item }) }}
                                selected={this.state.roundSelected}
                                arrowRight
                                collapsed
                            />}
                        </div>
                    </div>
                    <div className="right-section-container">
                        {(this.state.championshipSelected && this.state.roundSelected) &&
                            <ListRefertos
                                competition={this.state.championshipSelected}
                                round={this.state.roundSelected}
                                onStateChange={
                                    () => {
                                        const idChampionship = this.state.championshipSelected
                                        const idRound = this.state.roundSelected
                                        this.setChampionshipCount(idChampionship, false, this.state.countObjects[idChampionship].count - 1)
                                        this.setRoundCount(idChampionship, idRound, false, this.state.countObjects[idChampionship][idRound].count - 1)
                                    }
                                }
                            />
                        }
                    </div>
                </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.app,
    logged: state.logged
});

const mapActionsToProps = {
    actGlobalFetch,
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(RefertiGaraList));