import React from 'react'
import { Table, Divider, Tag } from 'antd';
import "./style.css"

class SimpleTable extends React.Component {

    render() {
        return (
            <div className={"simplelist-container"}>
                {"title" in this.props && <h3 style={{ margin: '16px 0' }}>{this.props.title}</h3>}
                <Table {...this.props} columns={this.props.columns} dataSource={this.props.dataSource} />
            </div>
        )
    }
}

export default SimpleTable