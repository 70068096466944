import React from 'react'
import { Tag, Icon } from 'antd'
import { ActionBar } from '@golee/golee-design-system'

const STATUS = {
    WAITING: "WAITING",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED"
}
const STATUS_ITA = {
    WAITING:  "IN ATTESA",
    APPROVED: "APPROVATO",
    REJECTED: "RIFIUTATO"
}
export const ACTION_TYPE = {
    APPROVE_REJECT: "APPROVE_REJECT",
    HISTORY: "HISTORY",
}

export const columns = (onClickModalCB) => [
    {
        title: 'Referto di',
        dataIndex: 'referto',
        key: 'referto',
        render: obj => <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Icon style={{ marginRight: 5 }} type="cloud-download" />
            <a style={{textDecoration: "underline"}} href={obj.document} target={"_blank"}>{obj.home.is_applicant ? obj.home.club_name : obj.away.is_applicant ? obj.away.club_name : ""}</a>
        </div>,
        className: "document-column"
    },
    {
        title: 'Giornata',
        dataIndex: 'step',
        key: 'step'
    },
    {
        title: 'Partita',
        dataIndex: 'match',
        key: 'match'
    },
    {
        title: 'Data',
        dataIndex: 'match_data',
        key: 'match_data'
    },
    {
        title: 'Stato',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
            let color = "yellow";
            if (status.status === STATUS.WAITING) color = "geekblue";
            else if (status.status === STATUS.APPROVED) color = "green";
            else if (status.status === STATUS.REJECTED) color = "volcano";
            return (
                <span>
                    <Tag color={color} key={status.status}>
                        {status.status === STATUS.APPROVED && status.app_rej_date ? status.app_rej_date : STATUS_ITA[status.status].toUpperCase()}
                    </Tag>
                </span>
            )
        }
    },
    {
        title: 'Gestisci',
        key: 'action',
        render: (row) =>
            <div style={{ display: "flex", justifyContent: "center" }} data-cy={`referto-manage-btn-${row.key}`}>
                <ActionBar
                    title={'Azioni'}
                    actions={[{
                        label: 'Approva / Rifiuta',
                        dataCy: 'radio_approva_rifiuta',
                        disabled: row.status.status === 'FORWARDED',
                        action: () => onClickModalCB(row, ACTION_TYPE.APPROVE_REJECT)
                    },
                    {
                        label: 'Attività',
                        disabled: row.status.status === 'FORWARDED',
                        action: () => onClickModalCB(row, ACTION_TYPE.HISTORY)
                    }]}
                />
            </div>
    }
]

export const prepareDataForTable = data => {
    return data.map((obj, i) => (
        {
            key: i,
            referto: obj,
            step: obj.competition.step,
            match: `${obj.home.club_name} - ${obj.away.club_name}`,
            match_data: obj.match_data ? obj.match_data : "-",
            status: {status: obj.status, app_rej_date: obj.app_rej_date},
            reject_motivation: obj.reject_motivation,
            id_referto: obj.id
        }
    )).sort((a, b) => {
        if(a.status.status === STATUS.WAITING) return -1
        else if(b.status.status === STATUS.WAITING) return 1
        else return 0
    })
}