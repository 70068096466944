// src/react-auth0-spa.js
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import { COOKIE_ACCESS_TOKEN, COOKIE_ROLE_ID, COOKIE_LIMITATIONS } from './const/globalConsts'


export const Auth0Context = React.createContext();



export const useAuth0 = () => useContext(Auth0Context);



export const Auth0Provider = ({
  children,
  onRedirectCallback,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [auth0Client, setAuth0Client] = useState();
  const [loading, setLoading] = useState(true);

  const saveAuthInfo = async (_auth0Client) => {
    setIsAuthenticated(true)
    const access_token = await _auth0Client.getTokenSilently()
    const IdTokenClaims = await _auth0Client.getIdTokenClaims()
    // Check roles
    if (IdTokenClaims['https://golee-eu.appspot.com/app_metadata']['roles'].filter(_ => !['figc-admin'].includes(_.role)).length) {
      console.log('Wrong role for this role. Logout.')
      _auth0Client.logout()
    }
    const _role = await IdTokenClaims["https://golee-eu.appspot.com/app_metadata"].roles.find(role => role.role === "figc-admin")
    const role_id = _role.role_id
    const limitations = _role.limitations
    localStorage.setItem(COOKIE_ACCESS_TOKEN, access_token)
    localStorage.setItem(COOKIE_ROLE_ID, role_id)
    localStorage.setItem(COOKIE_LIMITATIONS, JSON.stringify(limitations))
  }

  useEffect(() => {
    async function initClient() {
      const auth0 = await createAuth0Client(initOptions);
      setAuth0Client(auth0);
      return auth0
    }

    initClient().then(async (_auth0Client) => {
      if (process.env.REACT_APP_COOKIE_AUTHENTICATION) {
        console.log('Skippo autenticazione')
        setIsAuthenticated(true)
        setLoading(false)
      } else {
        const _isAuthenticated = await _auth0Client.isAuthenticated();
        if (_isAuthenticated) {
          console.log("User previously logged")
          await saveAuthInfo(_auth0Client)
        } else {
          if (window.location.search.includes("code=")) {
            const { appState } = await _auth0Client.handleRedirectCallback();
            onRedirectCallback(appState);
            console.log("User just logged")
            await saveAuthInfo(_auth0Client)
          }
          if(window.location.search.includes("error=unauthorized")) { window.location.replace('https://storage.googleapis.com/gle-assets/login-auth0/blocked.html')}
        }
        setLoading(false)
      }

    });

    // eslint-disable-next-line
  }, []);


  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        loading,
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        logout: (...p) => {
          auth0Client.logout(...p)
          localStorage.removeItem(COOKIE_ACCESS_TOKEN)
          localStorage.removeItem(COOKIE_ROLE_ID)
          localStorage.removeItem(COOKIE_LIMITATIONS)
        }
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
