import React, { Component } from 'react';
import './style.css'
import { connect } from 'react-redux';
import { actGlobalFetch, getStatusCode } from '../../../../redux/api/actions';
import { actSetInfo } from './../../../../redux/logged/actions'
import FIGCPlaceholder from './../../../../core-trainer/assets/images/placeholder/figc_placeholder.png'
import { Divider, Typography, Icon } from 'antd';
import ReactPlayer from 'react-player'
import { METHOD, SERVICES, URLDashboardDelegation, BODY_TYPE } from './../../../../core-trainer/assets/consts/api'
import { withSnackbar } from 'notistack';

class Landing extends Component{

    constructor(props){
        super(props)
        this.state = {
            data: null
        }
    }

    componentDidMount(){
        this.props.actGlobalFetch(URLDashboardDelegation(), SERVICES.BASE_MATCH, METHOD.GET, true, BODY_TYPE.EMPTY)
            .then(response => {
                if (getStatusCode(response) == 200) {
                    this.setState({ data: response.data })
                    this.props.actSetInfo(response.data)
                }
            })
    }


    render() { 
        return( <div className="landing-container">
        <div className="placeholders_container">
            {   this.props.logged.info && this.props.logged.info.logo ? 
                <img src={this.props.logged.info.logo} alt={"logo"}/>
                : <img src={FIGCPlaceholder} alt={"logo"}/>
            }
        </div>
        <div className={"second-section-container"}>
            <div className={"info-container"}>
                <Typography.Text className={"title"} strong>
                    {"Bisogno di supporto tecnico?"}
                </Typography.Text>
                <div className={"second-section-content"}>
                    <Typography.Paragraph style={{ textAlign: "center"}}>
                        <Typography.Text>{"contatta il Customer Care Golee"}</Typography.Text><br/>
                        <Typography.Text>{"dal lunedì al venerdi dalle ore 9.00 alle ore 18.00"}</Typography.Text>
                    </Typography.Paragraph>
                    
                    <div class="contacts">
                        <Typography.Paragraph style={{ textAlign: "center"}}>
                            <Icon type="mail" />
                            <Typography.Text>{" supporto@golee.it"}</Typography.Text>
                        </Typography.Paragraph>
                    </div>
                </div>

                {this.state.data &&
                    <>
                        <Typography.Text className={"title"} strong>
                            {"Hai bisogno di assistenza?"}
                        </Typography.Text>
                        <div className={"second-section-content"}>
                            {this.state.data && this.state.data.support_text_dashboard &&
                                <Typography.Paragraph style={{ textAlign: "center"}}>
                                    <Typography.Text>{this.state.data.support_text_dashboard}</Typography.Text>
                                </Typography.Paragraph>
                            }
                            <div class="contacts">
                                {this.state.data && this.state.data.support_tel &&
                                    <Typography.Paragraph style={{ textAlign: "center"}}>
                                        <Icon type="phone"/>
                                        <Typography.Text>{` ${this.state.data.support_tel}`}</Typography.Text>
                                    </Typography.Paragraph>
                                }
                                
                                {this.state.data && this.state.data.support_email &&
                                    <Typography.Paragraph style={{ textAlign: "center"}}>
                                        <Icon type="mail" />
                                        <Typography.Text>{` ${this.state.data.support_email}`}</Typography.Text>
                                    </Typography.Paragraph>
                                }
                            </div>
                        </div>
                    </>
                }

            </div>
            <Divider style={{ height: "100%" }} type="vertical" />
            <div className={"tutorial-container"}>
                <Typography.Text className={"title"} strong>
                    {"Tutorial"}
                </Typography.Text>
                <div className={"second-section-content"}>
                    <ReactPlayer width={"100%"} height={"100%"} controls url='https://www.youtube.com/watch?v=nWPQCNQfMUk' />
                </div>
            </div>
        </div>
    </div>)}

}


const mapStateToProps = state => ({
    app: state.app,
    logged: state.logged
});

const mapActionsToProps = {
    actGlobalFetch,
    actSetInfo
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(Landing));