import React, { Component } from 'react';
import Image from './../../../assets/imges/404_image.svg'
import { Typography } from 'antd'

class NotFound extends Component {

    componentDidMount(){
        document.title = "Errore"
    }

    render() {
        return (
            <div style={{display: "flex", flexDirection: "column", flex: 1, height:"100%", width: "100%", alignItems: "center"}}>
                <Typography.Title style={{ color: '#f73a33', margin: 50 }}>{"404 Pagina non trovata"}</Typography.Title>
                <img src={Image} style={{width: "33.3%"}} />
            </div>
        )
    }
}
export default NotFound