import React, { useState, useEffect } from "react";
// import Menu from "../../Components/Menu";
import { useAuth0 } from "../../react-auth0-spa";
import "./App.css"
import { COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN, COOKIE_TRAINER_ID, COOKIE_TEAM_ID } from '../../const/globalConsts'
import AppRouter from "../../router"
import LogoGolee from './../../core-trainer/assets/images/placeholder/logogolee.png'
import FIGCPlaceholder from './../../core-trainer/assets/images/placeholder/figc_placeholder.png'
import PlaceholderGolee from './../../core-trainer/assets/images/placeholder/Placeholder-Golee.png'
import FavIcon from './../../assets/imges/favicon.png'
import { Layout, Menu, Icon, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import themes from '../../settings/themes';
import { themeConfig } from '../../settings';
import { Spin } from 'antd'
import { connect } from 'react-redux';

const { Header, Sider, Content, Footer } = Layout;
const customizedTheme = themes[themeConfig.theme];

const getMenuElements = () => {
  return ([
    <Menu.Item key="/refertigara" route="/refertigara" data-cy="referti-gara-menu-item">{
      <span>
        <Icon type={"snippets"} />
        <span>{"Referti Gara"}</span>
      </span>
    }</Menu.Item>,
    <Menu.Item key="/richiestavariazionigare" route="/richiestavariazionigare" data-cy="variazioni-gare-menu-item">{
      <span>
        <Icon type="exception" />
        <span>{"Variazioni Gare"}</span>
      </span>
    }</Menu.Item>,
    <Menu.Item key="/richiesteamichevoli" route="/richiesteamichevoli" data-cy="richiesteamichevoli-menu-item">{
      <span>
        <Icon type="team" />
        <span>{"Richieste Amichevoli"}</span>
      </span>
    }</Menu.Item>
  ]
  )
}


function App(props) {
  const { isAuthenticated, loading, logout } = useAuth0();
  const [collapsed, setCollapsed] = useState(false);
  const [logoImage, setLogoImage] = useState(LogoGolee);
  const [logo, setLogo] = useState(null);
  var history = useHistory();

  const toggle = () => {
    setLogoImage(collapsed ? LogoGolee : PlaceholderGolee)
    setCollapsed(!collapsed)
  };

  const itemMenuClicked = (item, key, keyPath, domEvent) => {
    if (item.props.route) history.push(item.props.route)
  }

  useEffect(()=>{
    if(props.logged.info.logo){
      setLogo(props.logged.info.logo)
    }
  },[props.logged.info.logo])

  if (loading) {
    return <div style={{ height: "100vh", width: "100%", display: "flex", flex: "1", justifyContent: "center", alignItems: "center" }}>
          <Spin size="large"/>
    </div>
  }
  return (
    <Layout style={{ height: "100vh" }}>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className={"logo"} style={{ height: logoImage === PlaceholderGolee && "64px", cursor: 'pointer' }} onClick={()=>{history.push("/")}}>
          {logo && <img className="phfigc" src={logo} alt={"logo"}></img> }
          <img className="phfigc" src={FIGCPlaceholder} alt={"logo FIGC"}></img> 
        </div>
        <Menu theme="dark" mode="inline" selectedKeys={[history.location.pathname]} onClick={({ item, key, keyPath, domEvent }) => itemMenuClicked(item, key, keyPath, domEvent)}>
          {getMenuElements()}
        </Menu>
      </Sider>
      <Layout>
        <Header className={"App-header"}>
          <Icon
            className="trigger"
            type={collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={toggle}
          />
          <Button icon={"logout"} style={{ color: customizedTheme.palette.golee[0] }} size={"default"} onClick={() => {
            console.log("LOGOUT")
            localStorage.removeItem(COOKIE_ACCESS_TOKEN)
            logout()
          }}>Logout</Button>
        </Header>
        <div className={"AppContent-container"}>
          <Content className={"App-content"}>
            <AppRouter />
          </Content>
        </div>
        <Footer className={"App-footer"}><a href={"http://golee.it"} target={"_blank"}><img src={FavIcon} style={{ width: "20px" }} alt="Golee.it"/></a>{` Copyright © ${new Date().getFullYear()} Golee | version ${process.env.REACT_APP_ENV.substr(0, 1)}-${require('../../../package.json').version}`}</Footer>
      </Layout>
    </Layout>
  );
}



const mapStateToProps = state => ({
  app: state.app,
  logged: state.logged
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);