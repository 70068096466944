import React, { Component } from 'react';
import { Badge, Spin, Icon } from 'antd'
import './style.css'

const antIcon = <Icon type="loading" spin />;

class SimpleBadge extends Component {

    render() {
        return (
            <div className={"badge-container"}>
                <Badge className={"badge-element"} 
                    showZero
                    style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset', fontWeight: this.props.count && "bold"}} count={this.props.count}/>
                <Spin className={"spin-element"} 
                    indicator={antIcon} 
                    spinning={this.props.loading}/>
            </div>
        )
    }

}

export default SimpleBadge