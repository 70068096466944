import React, { Component } from 'react';
import { connect } from 'react-redux';
import { METHOD, SERVICES, URLMatchVariations, URLGetDelegations, URLUpdateStatusMatchVariations, URLForwardMatchVariation, BODY_TYPE } from '../../../../core-trainer/assets/consts/api'
import { actGlobalFetch, getStatusCode } from '../../../../redux/api/actions';
import { withSnackbar } from 'notistack';
import { Typography, Icon, Modal, Radio, Input, List } from 'antd'
import SimpleTable from '../../../../Components/Table/SimpleTable'
import { columns, prepareDataForTable, ACTION_TYPE, STATUS } from './columns'
import './style.css'
import { Select } from '@golee/golee-design-system'
import { reverseHistory } from '../../../../utils/functions';
import moment from 'moment';

const ACTION_STATUS_TYPE_ITA = (club_name, params = { target_delegation: { type: null, province: null, region: null } }, delegation = {}) => ({
    CREATED: [`Il Club Manager `, <b>{club_name}</b>, ` ha `, <b>inserito</b>, ` questa variazione gara`],
    APPROVED_FIGC: [`La delegazione Figc `, <b>{delegation.province || delegation.region}</b>, ` ha `, <b>approvato</b>, ` questo modulo`],
    REJECTED_FIGC: [`La delegazione Figc `, <b>{delegation.province || delegation.region}</b>, ` ha `, <b>rifiutato</b>, ` questo modulo`],
    APPROVED_OPPONENT: [`La richiesta di variazione gara è stata `, <b>approvata</b>, ` dal club avversario `, <b>{club_name}</b>],
    REJECTED_OPPONENT: [`La richiesta di variazione gara è stata `, <b>rifiutata</b>, ` dal club avversario `, <b>{club_name}</b>],
    SUBSTITUTED: [`Il club manager `, <b>{club_name}</b>, ` ha `, <b>aggiornato</b>, ` il modulo`],
    FORWARDED: [`La delegazione Figc `, <b>{delegation.province || delegation.region}</b>, ` ha `, <b>inoltrato</b>, ` questa richiesta alla delegazione `, params.target_delegation.type === 'PRO' ? `provinciale di ${params.target_delegation.province}` : `regionale di ${params.target_delegation.region}`]
})

class VariazioniGara extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isDownloadingData: false,
            listMatchVariations: [],
            selectedRow: { status: {} },
            modalApproveRejectVisible: false,
            modalForwardVisible: false,
            modalHistoryVisible: false,
            radioSelected: null,
            textInputValue: "",
            selected_delegation: null
        }
    }

    componentDidMount() {
        document.title = "Variazioni Gara"
        this.fetchData()
    }

    resetModal = () => {
        this.setState({
            modalApproveRejectVisible: false,
            modalForwardVisible: false,
            modalHistoryVisible: false,
            radioSelected: null,
            textInputValue: "",
            selectedRow: { status: {} },
            selected_delegation: null
        })
    }

    onClickModalCB = (selectedRow, actionType) => {
        if (actionType == ACTION_TYPE.APPROVE_REJECT) {
            this.setState({
                modalApproveRejectVisible: true,
                selectedRow: selectedRow,
                radioSelected: selectedRow.status.status,
                textInputValue: selectedRow.reject_motivation
            })
        } else if (actionType == ACTION_TYPE.FORWARD) {
            this.setState({
                modalForwardVisible: true,
                selectedRow: selectedRow,
                selected_delegation: null
            })
        } else if (actionType == ACTION_TYPE.HISTORY) {
            this.setState({
                modalHistoryVisible: true,
                selectedRow: selectedRow,
                selected_delegation: null
            })
        }
    }

    onConfirmForwardModalButton = () => {
        var _listMatchVariations = this.state.listMatchVariations
        const _idRichiesta = this.state.selectedRow.id_match_variation
        const delegationId = this.state.selected_delegation
        this.resetModal()
        this.props.actGlobalFetch(URLForwardMatchVariation(_idRichiesta), SERVICES.BASE_MATCH, METHOD.POST, true, BODY_TYPE.JSON, JSON.stringify({ target_delegation: delegationId })
        ).then(response => {
            if (getStatusCode(response) == 200) {
                const index = _listMatchVariations.findIndex(obj => obj.id === _idRichiesta)
                _listMatchVariations[index].status = STATUS.FORWARDED
                this.setState({ listRichieste: reverseHistory(_listMatchVariations) })
                this.props.enqueueSnackbar("Richiesta inoltrata", {
                    variant: "success",
                    'data-cy': 'snackbar_success_forward'
                });
            } else {
                this.showError()
            }
        }).catch((err) => {
            this.showError(err)
        })
    }
    onConfirmApproveRejectModalButton = () => {
        var _listMatchVariations = this.state.listMatchVariations
        const _idModule = this.state.selectedRow.id_match_variation
        const _radioSelected = this.state.radioSelected
        const _app_rej_date = `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`
        const _textInputValue = _radioSelected === "REJECTED" ? this.state.textInputValue : null

        this.resetModal()
        this.props.actGlobalFetch(
            URLUpdateStatusMatchVariations(_idModule),
            SERVICES.BASE_MATCH,
            METHOD.PUT,
            true,
            BODY_TYPE.JSON,
            JSON.stringify({ status: _radioSelected, reject_motivation: _textInputValue, app_rej_date: _app_rej_date })
        ).then(response => {
            if (getStatusCode(response) == 200) {
                const index = _listMatchVariations.findIndex(obj => obj.id === _idModule)
                _listMatchVariations[index].status = _radioSelected
                _listMatchVariations[index].reject_motivation = _textInputValue
                _listMatchVariations[index].app_rej_date = _app_rej_date
                this.setState({ listMatchVariations: reverseHistory(_listMatchVariations) })
                this.props.enqueueSnackbar("Stato modificato", {
                    variant: "success",
                    'data-cy': 'snackbar_success'
                });
            } else {
                this.showError()
            }
        }).catch((err) => {
            this.showError(err)
        })
    }
    onDiscardModalButton = () => {
        this.resetModal()
    }

    onTextInputChange = ({ target: { value } }) => { this.setState({ textInputValue: value }) }

    fetchData = () => {
        this.setState({ isDownloadingData: true })
        this.props.actGlobalFetch(URLMatchVariations(), SERVICES.BASE_MATCH, METHOD.GET, true, BODY_TYPE.EMPTY)
            .then(response => {
                if (getStatusCode(response) == 200) {
                    this.setState({ listMatchVariations: reverseHistory(response.data) })
                } else {
                    this.showError()
                }
                this.setState({ isDownloadingData: false })
            })
            .catch((err) => {
                this.showError(err)
                this.setState({ isDownloadingData: false })
            })
    }

    showError(e = null) {
        this.props.enqueueSnackbar("Ops, qualcosa è andato storto", {
            variant: "error"
        });
    }

    render() {
        const getTitle = () => `${this.state.selectedRow.competition}`
        return (
            this.state.isDownloadingData ?
                <div>
                    <Typography>Download Variazioni...</Typography>
                    <Icon type="loading" />
                </div>
                :
                <div className="variazioni-gara-container">
                    <SimpleTable columns={columns(this.onClickModalCB)} dataSource={prepareDataForTable(this.state.listMatchVariations)} pagination={false} loading={this.state.isDownloadingData} size={"small"} headerSort={false} />

                    {/* MODAL APPROVE-REJECT */}
                    <Modal
                        title={getTitle()}
                        visible={this.state.modalApproveRejectVisible}
                        onOk={this.onConfirmApproveRejectModalButton}
                        onCancel={this.onDiscardModalButton}
                        okButtonProps={{ disabled: (this.state.radioSelected === this.state.selectedRow.status.status && this.state.textInputValue === this.state.selectedRow.reject_motivation) }}
                    >
                        <Radio.Group onChange={(e) => { this.setState({ radioSelected: e.target.value }) }} value={this.state.radioSelected}>
                            <Radio data-cy={"radio_approva"} value={"APPROVED"}>{"Approva"}</Radio>
                            <Radio data-cy={"radio_rifiuta"} value={"REJECTED"}>{"Rifiuta"}</Radio>
                        </Radio.Group>

                        {this.state.radioSelected === "REJECTED" && <Input.TextArea style={{ marginTop: 10 }}
                            placeholder="Motivazione rifiuto"
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            onChange={this.onTextInputChange}
                            value={this.state.textInputValue}
                            data-cy={"textarea_motivazione"}
                        />}
                    </Modal>

                    {/* MODAL FORWARD */}
                    <Modal
                        title={getTitle()}
                        visible={this.state.modalForwardVisible}
                        onOk={this.onConfirmForwardModalButton}
                        onCancel={this.onDiscardModalButton}
                        zIndex={1}
                        okButtonProps={{ disabled: (!this.state.selected_delegation ? true : false) }}
                        destroyOnClose
                    >
                        <Select.Async
                            label={"Seleziona la delegazione"}
                            data-cy={"select_delegation"}
                            menuPortalTarget={document.body}
                            loadOptions={async () => {
                                const _delegations = await this.props.actGlobalFetch(URLGetDelegations(), SERVICES.BASE_MATCH, METHOD.GET, true, BODY_TYPE.EMPTY)
                                return {
                                    options: _delegations.data.map(d => ({ label: `${d.type == "REG" ? `Delegazione regionale ${d.region}` : `Delegazione provinciale ${d.province}`}`, value: d._id }))
                                }
                            }}
                            onChange={(option) => { this.setState({ selected_delegation: option.value }); }}
                        />
                    </Modal>

                    {/* MODAL HISTRY */}
                    <Modal
                        title={getTitle()}
                        visible={this.state.modalHistoryVisible}
                        onOk={this.onDiscardModalButton}
                        onCancel={this.onDiscardModalButton}
                        zIndex={1}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        destroyOnClose
                    >
                        <List dataSource={this.state.selectedRow.document && this.state.selectedRow.document.events_history}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        title={ACTION_STATUS_TYPE_ITA(item.who_made_club_name, item.params, item.who_made_delegation)[item.action_type]}
                                        description={moment(Number(item.datetime)).format("HH:mm DD/MM/YYYY")}
                                    />
                                </List.Item>
                            )}
                        />
                    </Modal>
                </div>
        );
    }
}


const mapStateToProps = state => ({
    app: state.app,
    logged: state.logged
});

const mapActionsToProps = {
    actGlobalFetch,
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(VariazioniGara));