import React from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./Components/PrivateRoute";
import RefertiGaraNuoviList from './containers/AppPages/logged/RefertiGara';
import RichiesteAmichevoliList from './containers/AppPages/logged/RichiesteAmichevoli';
import VariazioniGaraNuoviList from './containers/AppPages/logged/VariazioniGara';
import Landing from './containers/AppPages/logged/Landing';
import NotFound from './containers/AppPages/NotFound';

export default () => (
    <Switch>
        <PrivateRoute path="/" component={Landing} exact />
        {/** refertigara */}
        <PrivateRoute path="/refertigara" component={RefertiGaraNuoviList} exact/>
        <PrivateRoute path="/richiesteamichevoli" component={RichiesteAmichevoliList} exact/>
        <PrivateRoute path="/richiestavariazionigare" component={VariazioniGaraNuoviList} exact/>
        <Route component={NotFound} />
    </Switch>
)