import { combineReducers } from 'redux'

//import appReducer from './app'
import appReducer from './app/reducer'
import loggedReducer from './logged/reducer'

const combinedReducers = combineReducers({
    app: appReducer,
    logged: loggedReducer
})

export default combinedReducers