import ReduxThunk from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import combineReducers from './reducers'

var composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    combineReducers, 
    composeEnhancer(applyMiddleware(ReduxThunk))
);
store.subscribe(() => console.log(store.getState()))

export default store