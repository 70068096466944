export default {
    apiUrl: 'http://yoursite.com/api/',
};

const siteConfig = {
    siteName: 'GOLEE',
    siteIcon: 'ion-flash',
    footerText: 'GOLEE ©2019 Created by Sweeft Digital, Inc',
};
const themeConfig = {
    topbar: 'themedefault',
    sidebar: 'themedefault',
    layout: 'themedefault',
    theme: 'themedefault',
};
const language = 'english';

const jwtConfig = {
    fetchUrl: '/api/',
    secretKey: 'secretKey',
};

export const perPage = 30;

export {siteConfig, language, themeConfig, jwtConfig};
