export const USER_FIELD = "ACT_USER_FIELD"
export const IS_LOGGED = "ACT_IS_LOGGED"
export const SET_ACCESS_TOKEN = "ACT_SET_ACCESS_TOKEN"
export const SET_REFRESH_TOKEN = "ACT_SET_REFRESH_TOKEN"
export const SET_EMAIL = "ACT_SET_EMAIL"
export const SET_IS_LOADING = "ACT_SET_IS_LOADING"
export const SET_URL = "ACT_SET_URL"
export const SET_IS_LOGGED = "ACT_SET_IS_LOGGED"

export const SET_LIST_CLUB = "ACT_SET_LIST_CLUB"
export const SET_LIST_TRAINER = "ACT_SET_LIST_TRAINER"
export const SET_LIST_TEAM = "ACT_SET_LIST_TEAM"
export const SET_TREE_CLUB_TEAM_TRAINER = "ACT_SET_TREE_CLUB_TEAM_TRAINER"
export const SET_LIST_INVITE = "ACT_SET_LIST_INVITE"
export const SET_TREE_INVITE_TEAM_TRAINER = "ACT_SET_TREE_INVITE_TEAM_TRAINER"

export const SET_INFO = "ACT_SET_INFO"