import React from 'react'
import { Tag, Button, Modal, Icon } from 'antd'
import { ActionBar } from '@golee/golee-design-system'

export const STATUS = {
    WAITING: "WAITING",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    FORWARDED: "FORWARDED"
}
const STATUS_ITA = {
    WAITING: "IN ATTESA",
    APPROVED: "APPROVATO",
    REJECTED: "RIFIUTATO",
    FORWARDED: "INOLTRATO"
}
export const ACTION_TYPE = {
    APPROVE_REJECT: "APPROVE_REJECT",
    FORWARD: "FORWARD",
    HISTORY: "HISTORY",
}

export const columns = (onClickModalCB) => [
    {
        title: 'Modulo di',
        dataIndex: 'document',
        key: 'document',
        render: obj => <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Icon style={{ marginRight: 5 }} type="cloud-download" />
            <a style={{ textDecoration: "underline" }} href={obj.document} target={"_blank"}>{obj.home.is_applicant ? obj.home.club_name : obj.away.is_applicant ? obj.away.club_name : ""}</a>
        </div>,
        className: "document-column"
    },
    {
        title: 'Giornata',
        dataIndex: 'step',
        key: 'step'
    },
    {
        title: 'Campionato',
        dataIndex: 'competition',
        key: 'competition'
    },
    {
        title: 'Partita',
        dataIndex: 'match',
        key: 'match'
    },
    {
        title: 'Data e Luogo',
        dataIndex: 'dateplace',
        key: 'dateplace'
    },
    {
        dataIndex: 'arrow',
        key: 'arrow'
    },
    {
        title: 'Variazione',
        dataIndex: 'variations',
        key: 'variations'
    },
    {
        title: 'Stato club avversario',
        dataIndex: 'other_club_status',
        key: 'other_club_status',
        render: (other_club_status) => {
            let color = "yellow";
            if (other_club_status.status === STATUS.WAITING) color = "geekblue";
            else if (other_club_status.status === STATUS.APPROVED) color = "green";
            else if (other_club_status.status === STATUS.REJECTED) color = "volcano";
            return (
                other_club_status.status && <span>
                    <Tag color={color} key={other_club_status.status}>
                        {STATUS_ITA[other_club_status.status].toUpperCase()}
                    </Tag>
                </span>
            )
        }
    },
    {
        title: 'Stato',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
            let color = "yellow";
            if (status.status === STATUS.WAITING) color = "geekblue";
            else if (status.status === STATUS.APPROVED) color = "green";
            else if (status.status === STATUS.REJECTED) color = "volcano";
            return (
                <span>
                    <Tag color={color} key={status.status}>
                        {status.status === STATUS.APPROVED && status.app_rej_date ? status.app_rej_date : STATUS_ITA[status.status].toUpperCase()}
                    </Tag>
                </span>
            )
        }
    },
    {
        title: 'Gestisci',
        key: 'action',
        render: (row) =>
            <div style={{ display: "flex", justifyContent: "center" }} data-cy={`variazioni-gare-manage-btn-${row.key}`}>
                <ActionBar
                    title={'Azioni'}
                    actions={[{
                        label: 'Approva / Rifiuta',
                        dataCy: 'radio_approva_rifiuta',
                        disabled: row.status.status === 'FORWARDED',
                        action: () => onClickModalCB(row, ACTION_TYPE.APPROVE_REJECT)
                    },
                    {
                        label: 'Inoltra',
                        dataCy: 'btn_forward',
                        disabled: row.status.status === 'FORWARDED',
                        action: () => onClickModalCB(row, ACTION_TYPE.FORWARD)
                    },
                    {
                        label: 'Attività',
                        dataCy: 'btn_activities',
                        disabled: row.status.status === 'FORWARDED',
                        action: () => onClickModalCB(row, ACTION_TYPE.HISTORY)
                    }]}
                />
            </div>
    }
]

export const prepareDataForTable = data => {
    return data.map((obj, i) => (
        {
            key: i,
            document: obj,
            step: obj.competition.step,
            competition: obj.competition.competition_name,
            match: `${obj.home.club_name} - ${obj.away.club_name}`,
            dateplace: `${obj.match_data ? obj.match_data : ''} ${obj.time ? obj.time : ''} ${obj.place ? `, ${obj.place}` : ''}`,
            arrow: <Icon type="arrow-right" />,
            variations: obj.variations && `${obj.variations.date ? obj.variations.date : ""} ${obj.variations.time ? obj.variations.time : ""} ${obj.variations.place ? obj.variations.place : ""}`,
            status: { status: obj.status, app_rej_date: obj.app_rej_date },
            other_club_status: { status: obj.other_club_status, },
            reject_motivation: obj.reject_motivation,
            id_match_variation: obj.id
        }
    )).sort((a, b) => {
        if (a.status.status === STATUS.WAITING) return -1
        else if (b.status.status === STATUS.WAITING) return 1
        else return 0
    })
}