import {
    SET_ACCESS_TOKEN
} from '../../const/actionType';
import { saveAccessToken } from "../../const/persistentStore"
import {ENV} from "./../../const/api"
import { getHeader, URLAuthRefresh, METHOD, BODY_TYPE, SERVICES, BUILD_TYPE } from '../../core-trainer/assets/consts/api'
import { COOKIE_ROLE_ID, COOKIE_ACCESS_TOKEN } from './../../const/globalConsts'

//HTTP REQUEST
/**
 * 
 * @param {string} url url to call
 * @param {int} service which service to use
 * @param {string} method (GET,POST,DELETE,UPDATE,..)
 * @param {boolean} isAuthorized if authorized, set access_token in header
 * @param {int} bodyType which type of body to use
 * @param {*} body json with payload
 */
export function actGlobalFetch(url, service, method, isAuthorized=true, bodyType=BODY_TYPE.JSON, body=null) {
    return (dispatch,getState) => {
        var _url = null
        if(ENV == 'prod') { _url = service[BUILD_TYPE.PRODUCTION] + url}
        else if(ENV == 'staging') { _url = service[BUILD_TYPE.STAGING] + url}
        else if(ENV == 'local') { _url = service[BUILD_TYPE.LOCAL] + url}
        else if(ENV == 'testing') { _url = service[BUILD_TYPE.TESTING] + url }
        let access_token = localStorage.getItem(COOKIE_ACCESS_TOKEN)
        let role_id = localStorage.getItem(COOKIE_ROLE_ID)
        var _header = getHeader(isAuthorized, bodyType, access_token, role_id)
        
        console.log("[ FETCH "+_url+"] ")
        console.log("With method: ", method)
        console.log("is Authorization required: ", isAuthorized)
        console.log("With params: ", body)
        console.log("With headers: ", _header)
        
        return fetch(_url, {
            crossDomain:true,
            method: method,
            headers: _header,
            body: body
        })
        .then(r =>  r.json().then(data => ({status: r.status, data: data})))
        .then(async (responseJson) => {
            var responseJsonBlob = {}
            responseJsonBlob["data"] = responseJson.data
            responseJsonBlob["status"] = responseJson.status
            console.log("[ RESPONSE FOR "+_url+"] ", responseJsonBlob)
            
            //token expired
            if(getStatusCode(responseJsonBlob) == 401){
                console.log('WARNING: Access token is expired. Reloading page...')

                window.location.reload(false);
            }
            //Otherwise, all right
            return responseJsonBlob
        })
        .catch((errorCath)=>{
            console.log("[ ERROR WHILE CALLING "+_url+"]",errorCath)
        })
    }
}

export function getStatusCode(jsonResponse) {
    return jsonResponse && "status" in jsonResponse ? jsonResponse.status : null
}