import {
    SET_LIST_CLUB,
    SET_LIST_TRAINER,
    SET_LIST_TEAM,
    SET_TREE_CLUB_TEAM_TRAINER,
    SET_LIST_INVITE,
    SET_TREE_INVITE_TEAM_TRAINER,
    SET_INFO
} from './../../const/actionType'

const INITIAL_STATE = { 
    info: {},
    listClub: [],
    listTrainer: [],
    listTeam: [],
    treeClTeTr: [],
    listInvite: [],
    treeInTeTr: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type){
        case SET_INFO:{
            return { ...state, info: action.payload };
        }
        case SET_LIST_CLUB:{
            console.log("in reducer setto lista club", action.payload)
            return { ...state, listClub: action.payload };
        }
        case SET_LIST_TRAINER:{
            console.log("in reducer setto lista trainer", action.payload)
            return { ...state, listTrainer: action.payload };
        }
        case SET_LIST_TEAM:{
            console.log("in reducer setto lista team", action.payload)
            return { ...state, listTeam: action.payload };
        }
        case SET_TREE_CLUB_TEAM_TRAINER:{
            console.log("in reducer setto tree club-team-trainer", action.payload)
            return { ...state, treeClTeTr: action.payload };
        }
        case SET_LIST_INVITE:{
            console.log("in reducer setto list invite", action.payload)
            return { ...state, listInvite: action.payload };
        }
        case SET_TREE_INVITE_TEAM_TRAINER:{
            console.log("in reducer setto tree invite-team-trainer", action.payload)
            return { ...state, treeInTeTr: action.payload };
        }
        default:
            return state;
    }
}