import {
    SET_LIST_CLUB,
    SET_LIST_TRAINER,
    SET_LIST_TEAM,
    SET_TREE_CLUB_TEAM_TRAINER,
    SET_LIST_INVITE,
    SET_TREE_INVITE_TEAM_TRAINER,
    SET_INFO
} from '../../const/actionType';

export const actSetInfo = (data) => {
    return({
        type: SET_INFO,
        payload: data
    })
}

export const actSetListClub = (list) => {
    return({
        type: SET_LIST_CLUB,
        payload: list
    })
}

export const actSetListTrainer = (list) => {
    return({
        type: SET_LIST_TRAINER,
        payload: list
    })
}

export const actSetListTeam = (list) => {
    return({
        type: SET_LIST_TEAM,
        payload: list
    })
}

export const actSetListTreeClTeTr = (list) => {
    return({
        type: SET_TREE_CLUB_TEAM_TRAINER,
        payload: list
    })
}

export const actSetListInvite = (list) => {
    return({
        type: SET_LIST_INVITE,
        payload: list
    })
}

export const actSetListTreeInTeTr = (list) => {
    return({
        type: SET_TREE_INVITE_TEAM_TRAINER,
        payload: list
    })
}