export const BUILD_TYPE = {
    "STAGING": "STAGING",
    "PRODUCTION": "PRODUCTION",
    "LOCAL": "LOCAL",
    "TESTING": "TESTING"
}

export const SERVICES = {
    BASE: {
        [BUILD_TYPE.STAGING]: 'https://beta-dot-trainer-core-dot-golee-eu.appspot.com/',
        [BUILD_TYPE.PRODUCTION]: "https://trainer-core-dot-golee-eu.appspot.com/",
        [BUILD_TYPE.LOCAL]: "http://TODO:/"
    },
    BASE_CORE: {
        [BUILD_TYPE.STAGING]: 'https://stories-web-api.golee.dev/', 
        [BUILD_TYPE.PRODUCTION]: "https://prod-web-api.golee.dev/",
        [BUILD_TYPE.LOCAL]: 'https://stories-web-api.golee.dev/',
        [BUILD_TYPE.TESTING]: 'https://stories-web-api.golee.dev/'
    },
    BASE_DEVTOOLS: {
        [BUILD_TYPE.STAGING]: 'https://staging-devtools.goleeapi.dev/', 
        [BUILD_TYPE.PRODUCTION]: "https://prod-devtools.goleeapi.dev/",
        [BUILD_TYPE.LOCAL]: 'http://localhost:TODO:/',
        [BUILD_TYPE.TESTING]: 'TODO:'
    },
    BASE_MEDIA: {
        [BUILD_TYPE.STAGING]:"https://staging-pepa.goleeapi.dev/",
        [BUILD_TYPE.PRODUCTION]:"https://prod-pepa.goleeapi.dev/",
        [BUILD_TYPE.LOCAL]: "http://TODO:/",
        [BUILD_TYPE.TESTING]:"https://testing-dot-pepa-service-dot-golee-eu.appspot.com/",
    },
    BASE_PEOPLE: {
        [BUILD_TYPE.STAGING]:"https://staging-people.goleeapi.dev/",
        [BUILD_TYPE.PRODUCTION]:"https://prod-people.goleeapi.dev/",
        [BUILD_TYPE.LOCAL]: "http://localhost:8083/",
        [BUILD_TYPE.TESTING]:"https://testing-dot-people-service-dot-golee-eu.appspot.com/",
    },
    BASE_WORKOUT: {
        [BUILD_TYPE.STAGING]:"https://staging-workouts.goleeapi.dev/",
        [BUILD_TYPE.PRODUCTION]:"https://prod-workouts.goleeapi.dev/",
        [BUILD_TYPE.LOCAL]: "http://localhost:8081/",
        [BUILD_TYPE.TESTING]:"https://testing-dot-pepa-service-dot-golee-eu.appspot.com/",
    },
    BASE_MATCH: {
        [BUILD_TYPE.STAGING]:"https://staging-matches.goleeapi.dev/",
        [BUILD_TYPE.PRODUCTION]:"https://prod-matches.goleeapi.dev/",
        [BUILD_TYPE.LOCAL]: "http://localhost:8085/",
        [BUILD_TYPE.TESTING]: "https://testing-matches.goleeapi.dev/"
    }
}

//METHOD
export const METHOD = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE"
}

export const BODY_TYPE = {
    EMPTY: -1,
    JSON: 0,
    MEDIA: 1,
    FORM_ENCRYPTED: 2
}

//HTTP REQUEST
export function getHeader(isAuthorized, bodyType, access_token=null, profile_id=null){
    var _header = {}
    switch(bodyType){
        case BODY_TYPE.EMPTY:
            _header = {}
            break;
        case BODY_TYPE.JSON:
            _header = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
            break;
        case BODY_TYPE.MEDIA:
            // _header = {
            //     'Content-Type': 'multipart/form-data'
            // }
            break;
        default:
            _header = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
    }
    if(profile_id) _header['x-gle-role'] = profile_id
    if(isAuthorized) _header['Authorization'] = 'Bearer '+ access_token
    return _header
}

export const DIRECTION = {
    NEXT: "next",
    PASSED: "passed",
}

export const API_KEY_BUGSNAG = "44f2c8c77d309f01b77de652d6b9925f"

/* ENTITY */
const teamEntity = "Team"
const clubEntity = "Club"
const trainerEntity = "Trainer"
const inviteEntity = "TrainerInvite"
const playerEntity = "Player"
const matchEntity = "match"
const matchesEntity = "matches"
const workoutEntity = "workout"
const workoutsEntity = "workouts"
const presencesEntity = "presences"
const delayEntity = "delays"
const statEntity = "stat"
const statsEntity = "stats"
const workoutType = "workouttype"
const contactEntity = "Contact"
const matchrefertos = "matchrefertos"
const matchvariations = "matchvariations"
const friendlymatchrequests = "friendlymatchrequests"
const championships = "championships"

/* KEYWORDS */
const dev = "dev"
const auth = "auth"
const login = "login"
const defaultKey = "default"
const skills = "skills"
const workout = "workout"
const recent = "recent"
const nworkout = "nworkout"
const absences = "absences"
const trainerRequest = "TrainerRequest"
const reset = "reset"
export const resetpassword = "reset-password"
const changemail = "changemail"
const report = "report"
const convocation = "MatchConvocation"
const summary = "summary"
const matchEvent = "MatchEvent"
const firstChoices = "first-choices"
const minVersionCheck = "minversion"
export const welcome = "welcome"
const refresh = "refresh"
const secure = "secure"
const teams = "teams"
const stats = "stats"
const player = "player"
const goalsStats = "goals"
const partsStats = "parts"
const images = "images"
const club_id = "club_id"
const typeKey = "type"
const dateKey = "date"
const lineup = "lineup"
const tools = "tools"
const initial_registration = "initial-registration"
const users = "users"
const byEmail = "byEmail"
const big_list = "biglist"
const migrationinfo = "migrationinfo"
const resendEmail = "resend-email"
const api = "api"
const athletes = "athletes"
const friendlymatches = "friendlymatches"
const competition = "competition"
const round = "round"
const status = "status"
const counter = "counter"
const external = "external"
const approved = "approved"
const currentSeason = "currentSeason"
const byteam = "byteam"
const bydelegation = "by-delegations"


/* APP API */
export const URLMinVersionCheck = (platform) => {return `${api}/${minVersionCheck}/${platform}` }

/* CORE  */
export const URLGetClubByName = (name) => {return `${api}/s/clubs/by-name?name=${name}` }
export const URLGetListClubByName = (name=null, start=null, limit=null) => {return `${api}/external/clubs?${name ? `name=${name}&` : ``}${start != null ? `start=${start}&` : ``}${limit != null ? `limit=${limit}&` : ``}` }
export const URLGetTeamListOfClubIdByName = (club_id, name=null, start=null, limit=null) => {return `${api}/external/clubs/${club_id}/teams?${name ? `name=${name}&` : ``}${start != null ? `start=${start}&` : ``}${limit != null ? `limit=${limit}` : ``}` }
export const URLGetTeamTemplates = () => {return `${api}/s/teams/team-templates` }
export const URLGetTeamById = (id_team) => {return `${api}/s/teams/${id_team}/details` }

/* AUTH API*/
export const URLRequestResetPassword = () => {return `${api}/accounts/${resetpassword}` }
export const URLVerifyAccessToken = () => {return secure}
export const URLAuthRefresh = () => {return auth + "/" + refresh}
export const URLWelcome = (slug) => {return welcome + "/" + slug}
export const URLLogin = () => {return auth + "/" + login }
export const URLGetTeamsID = (email) => {return trainerEntity + "/" + email + "/" + teams}
export const URLNewPassword = (email,pswRequestId) => {return auth + "/" + resetpassword + "/" + String(email).toLowerCase() + "/" + pswRequestId}

/* USER */
export const URLUserByEmail = (email) => {return auth + "/" + users + "/" + byEmail + "/" + email}
export const URLUserById = (id) => {return users + "/" + id}
export const URLSummaryUser = () => {return auth + "/" + summary}

/* PEOPLE */
export const URLBindPeople = () => {return `${api}/person/bind`}
/* ATHLETES */
export const URLGetTeamPlayers = (team_id) => {return `${api}/${athletes}?${byteam}=${team_id}`}
export const URLGetPutDeletAthleteOfTeam = (athlete_id) => {return `${api}/${athletes}/${athlete_id}`}
export const URLAddAthlete = () => {return `${api}/${athletes}`}
export const URLArchiveAthlete = (athlete_id) => {return `${api}/${athletes}/${athlete_id}/archivia`}

/* TECHNICAL STAFF */
export const URLTechnicalStaff = (technicalstaff_id=null) => {return `${api}/technicalstaff${technicalstaff_id ? `/${technicalstaff_id}` : ``}`  } 
export const URLTechnicalStaffByPersonId = (person_id) => {return `${api}/technicalstaff/person/${person_id}`} 
export const URLTechnicalStaffLoggedIn = (technicalstaff_id) => {return `${api}/technicalstaff/${technicalstaff_id}/loggedin`} 

/* INVITE API */
export const URLInvite = () => {return inviteEntity} //GET/POST
export const URLGetInviteDetail = (inviteCode) => {return inviteEntity + "/" + inviteCode }
export const URLResetEmailNewInvite = (inviteCode) => {return inviteEntity + "/" + reset + "/" + changemail}
export const URLResendInviteMail = (trainerId, teamId) => {return trainerEntity + "/" + trainerId + "/" + inviteEntity + "/" + teamId + "/" + resendEmail}

/* MEDIA API */
//GET
export const URLGetMediaOfTeam = (teamKey,isPost,start=null,limit=null) => {return `${api}/files${!isPost ? `/byTags` : ``}?team=${teamKey}&${start != null ? `start=${start}&` : ``}${limit != null ? `limit=${limit}` : ``}`}  
export const URLGetMediaOfTeamWithType = (teamKey,type,isPost,start=null,limit=null) => {return `${URLGetMediaOfTeam(teamKey,isPost,start,limit)}&type=${type}`}
export const URLGetMediaOfTeamMatch = (teamKey,matchKey,isPost,start=null,limit=null) => {return `${URLGetMediaOfTeamWithType(teamKey,"match",isPost,start,limit)}&match=${matchKey}`}  
export const URLGetMediaOfTeamWorkout = (teamKey,workoutKey,isPost,start=null,limit=null) => {return `${URLGetMediaOfTeamWithType(teamKey,"workout",isPost,start,limit)}&workout=${workoutKey}`}  
export const URLPostMediaWithDate = (base_get,date) => {return base_get + "&" + dateKey + "=" + date}  
export const URLDeleteMedia = (media_id) => {return `${api}/files/${media_id}` } 

export const URLGetMediaOfClub = (clubKey,page=null) => {return images + "?" + club_id + "=" + clubKey + (page ? "&p=" + page : '')}  
export const URLGetLogoOfClub = (clubKey) => {return `${api}/files/byTags?club=${clubKey}&type=CLUBLOGO&latest=true` }  
export const URLGetPublicLogoOfClub = (clubKey) => {return `${api}/p/clubs/${clubKey}/logo` }  
export const URLPostLogoOfClub = (clubKey,date) => {return URLGetMediaOfClub(clubKey) + "&type=CLUBLOGO&date=" + date }  

/* PRESENCES/ABSENCES/DELAYS */
export const URLDeleteWorkoutPresence = (clubKey, teamKey, workoutKey, presenceKey) => {return clubEntity + "/" + clubKey + "/" + teamEntity + "/" + teamKey + "/" + workoutEntity + "/" + workoutKey + "/" + presencesEntity + "/" + presenceKey}

/* SKILL */
export const URLGetPostLastAthleteSkills = (athlete_id) => {return `${api}/${athletes}/${athlete_id}/${skills}`}

/* STATS */
export const URLGetNumTeamWorkout = (teamKey) => { return statEntity + "/" + teamEntity + "/" + teamKey + "/" + nworkout }
export const URLGetTeamPresence = (teamKey) => { return statEntity + "/" + teamEntity + "/" + teamKey + "/" + presencesEntity }
export const URLGetWorkoutStatsPart = (teamKey) => { return statEntity + "/" + teamEntity + "/" + teamKey + "/" + workoutType }

export const URLGetPlayerWorkouts = (playerKey) => {return statEntity + "/" + playerEntity  + "/" + playerKey + "/" + nworkout}
export const URLGetPlayerAbsences = (playerKey) => {return statEntity + "/" + playerEntity  + "/" + playerKey + "/" + absences}
export const URLGetPlayerPercentualPresences = (playerKey) => {return statEntity + "/" + playerEntity  + "/" + playerKey + "/" + presencesEntity}
export const URLGetPlayerPercentualDelay = (playerKey) => {return statEntity + "/" + playerEntity  + "/" + playerKey + "/" + delayEntity}
export const URLGetPlayerOfWorkoutsStats = (clubKey,teamKey,playerKey) => { return clubEntity + "/" + clubKey + "/" + teamEntity + "/" + teamKey + "/" +  workoutEntity + "/" + stats + "/" + player + "/" + playerKey}
export const URLGetPlayerOfMatchesStats = (clubKey,teamKey,playerKey) => { return clubEntity + "/" + clubKey + "/" + teamEntity + "/" + teamKey + "/" +  matchEntity + "/" + stats + "/" + player + "/" + playerKey}

/* TEAM API */
export const URLGetPutDeleteTeam = (teamKey) => {return teamEntity + "/" + teamKey}
export const URLDefaultTeam = () => {return teamEntity + "/" + defaultKey }
export const URLMigrationInfo = (teamKey) => {return migrationinfo + "/" + teamKey }

/* CLUB API */
export const URLGetPutClub = (clubKey) => { return clubEntity + "/" + clubKey }


/* TRAINER API */
export const URLTrainerRequestAccount = () => {return trainerRequest }
export const URLGetPutDeleteTrainer = (trainerKey) => {return trainerEntity + "/" + trainerKey}
export const URLTrainerUpdateFormSignup = (slug) => {return welcome + "/" + slug + "/" + trainerEntity }
export const URLSummary = (trainerKey,teamKey) => {return trainerEntity + "/" + trainerKey + "/" + summary + "/" + teamKey}
export const URLStatsTrainingParts = (clubKey,teamKey) => {return clubEntity + "/" + clubKey + "/" + teamEntity + "/" + teamKey + "/" + workoutEntity + "/" + stats + "/" + partsStats}
export const URLStatsTrainingGoals = (clubKey,teamKey) => {return clubEntity + "/" + clubKey + "/" + teamEntity + "/" + teamKey + "/" + workoutEntity + "/" + stats + "/" + goalsStats}

/* WORKOUT API */
export const URLGetTeamWorkout = (start, limit) => {return `${api}/${workoutsEntity}?start=${start}&limit=${limit}`}
export const URLGetTeamWorkoutWithDir = (start, limit, dir=DIRECTION.NEXT) => {return URLGetTeamWorkout(start, limit) + "&dir=" + dir}
export const URLCrudWorkout = (workout_id=null) => {return `${api}/${workoutsEntity}${workout_id ? `/${workout_id}` : ``}` }
export const URLRecentWorkout = () => { return workout + "/" + recent }
export const URLGetPostPutWorkoutPresence = (workout_id) => {return `${api}/${workoutsEntity}/${workout_id}/${presencesEntity}` }

/* MATCH API */
export const URLGetTeamMatches = (start, limit) => {return `${api}/${matchesEntity}?start=${start}&limit=${limit}`}
export const URLGetTeamMatchesWithDir = (start, limit, dir=DIRECTION.NEXT) => {return URLGetTeamMatches(start, limit) + "&dir=" + dir}
export const URLCrudMatch = (match_id=null) => {return `${api}/${matchesEntity}${match_id ? `/${match_id}` : ``}` }
export const URLCrudFriendlyMatch = (match_id=null) => {return `${api}/${friendlymatches}${match_id ? `/${match_id}` : ``}` }
export const URLUpdateResultMatch = (match_id) => {return `${URLCrudMatch(match_id)}/result`}
export const URLCrudEventsMatch = (match_id, event_id=null) => {return `${URLCrudMatch(match_id)}/events${event_id ? `/${event_id}` : ``}`}
export const URLDeleteEventsMatchByAction = (match_id, action_id) => {return `${URLCrudMatch(match_id)}/actions/${action_id}`}
export const URLUpdateHomeAway = (match_id, team_id) => {return `${api}/${matchesEntity}/${match_id}/homeaway/${team_id}` }


export const URLGetPutDeleteRecentMatch = (teamKey) => {return teamEntity + "/" + teamKey + "/" + matchEntity + "/" + recent}
export const URLGetReportMatch = (teamKey, matchKey) => {return teamEntity + "/" + teamKey + "/" + matchEntity + "/" + matchKey + "/" + report}
export const URLGetMatchesStatistics = (clubKey, teamKey, matchKey) => {return clubEntity + "/" + clubKey + "/" + teamEntity + "/" + teamKey + "/" + matchEntity + "/" + statsEntity}
export const URLGetLineup = (clubKey, teamKey, matchKey) => {return clubEntity + "/" + clubKey + "/" + teamEntity + "/" + teamKey + "/" + matchEntity + "/" + matchKey + "/" + lineup}

/* CONVOCATIONS */
export const URLDeleteConvocation = (teamKey, matchKey, convocationKey) => {return teamEntity + "/" + teamKey + "/" + matchEntity + "/" + matchKey + "/" + convocation + "/" + convocationKey}
//Perchè si creano o si richiedono un set di convocazioni ma si elimina solo 1 alla volta?
export const URLGetPostFirstChoices = (teamKey, matchKey) => {return teamEntity + "/" + teamKey + "/" + matchEntity + "/" + matchKey + "/" + convocation + "/" + firstChoices }

/* EVENTS */
export const URLGetPostEvents = (clubKey, teamKey, matchKey) => {return clubEntity + "/" + clubKey + "/" + teamEntity + "/" + teamKey + "/" + matchEntity + "/" + matchKey + "/" + matchEvent }
export const URLDeleteEvent = (clubKey, teamKey, matchKey, eventKey) => {return clubEntity + "/" + clubKey + "/" + teamEntity + "/" + teamKey + "/" + matchEntity + "/" + matchKey + "/" + matchEvent + "/" + eventKey}

/* CONTACTS */
export const URLGetPostContacts = (clubKey) => {return clubEntity + "/" + clubKey + "/" + contactEntity}
export const URLGetPutDelContact = (clubKey, contactKey) => {return clubEntity + "/" + clubKey + "/" + contactEntity + "/" + contactKey}

/* INVITE */
export const URLInitRegistration = () => {return tools + "/" + initial_registration }
const URLBaseInviteWithToken = (token) => { return URLInitRegistration() + "/" + token + "/" }
export const URLInviteStepClub = (token) => {return URLBaseInviteWithToken(token) + "step-club" }
export const URLInviteStepTeam = (token) => {return URLBaseInviteWithToken(token) + "step-team" }
export const URLInviteStepTrainer = (token) => {return URLBaseInviteWithToken(token) + "step-trainer" }
export const URLEndRegistration = (token) => {return URLBaseInviteWithToken(token) + "end-wizard" }

/* REFERTO */
export const URLReferto = (competitionKey, roundKey) => {return api + "/" + matchrefertos + "?" + competition + "=" + competitionKey + "&" + round + "=" + roundKey}
export const URLUpdateStatusReferto = (refertoKey) => {return api + "/" + matchrefertos + "/" + refertoKey + "/" + status }
export const URLRefertoCounterByCompetition = (competitionKey) => {return `${api}/${matchrefertos}/${counter}?${competition}=${competitionKey}`}
export const URLRefertoCounterByRound = (competitionKey, roundKey) => {return `${api}/${matchrefertos}/${counter}?${competition}=${competitionKey}&${round}=${roundKey}`}

/* MATCH VARIATIONS */
export const URLMatchVariations = () => {return api + "/" + matchvariations}
export const URLUpdateStatusMatchVariations = (matchvariationKey) => {return api + "/" + matchvariations + "/" + matchvariationKey + "/" + status }
export const URLForwardMatchVariation = (id) => {return `${api}/${matchvariations}/${id}/forward` }

/* RICHIESTE AMICHEVOLI */
export const URLRichiesteAmichevoli = () => {return api + "/" + friendlymatchrequests }
export const URLUpdateStatusRichiesteAmichevoli = (requestKey) => {return api + "/" + friendlymatchrequests + "/" + requestKey + "/" + status }
export const URLForwardRichiestaAmichevole = (id) => {return `${api}/${friendlymatchrequests}/${id}/forward` }

/* DELEGATIONS */
export const URLGetDelegations = () => {return `${api}/figc/delegations`}

/* GENERAL */
export const URLListClubs = () => {return clubEntity}
export const URLListTeams = () => {return teamEntity}
export const URLListTeamsOfClub = (clubId) => {return teamEntity + "?club=" + clubId}
export const URLListTrainers = () => {return trainerEntity}
export const URLDashboardDelegation = () => {return `${URLGetDelegations()}/dashb`}

/* CHAMPIONSHIP */
export const URLListChampionships = (limitations = []) => { 
    const _limitQueryParam = limitations.map(limitation => 
        `${limitation.type == 'region' ? 'delegation_region' : 'delegation_province'}=${limitation.values.join(",")}&`
    )
    return `${api}/${external}/${championships}/${bydelegation}?${_limitQueryParam}${approved}=true&${currentSeason}=true`
}

export const URLBigList = (page) => {return dev + "/" + big_list + "?p=" + page}
