import styled from 'styled-components';
// import {palette, font} from 'styled-theme';
/**
 * Override AntD style
 */
const AntDStyle = styled.div`
  .ant-row {
    ${'' /* margin-left: -8px;
    margin-right: -8px; */};
  }
  .ant-layout-sider,
  .ant-menu-dark, .ant-menu-dark .ant-menu-sub{
      background: #2e74b5 ;
  }
  .ant-typography strong{
    font-weight:bold;
  }
`;

export default AntDStyle;