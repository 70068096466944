import React from 'react'
import { connect } from 'react-redux';
import { METHOD, SERVICES, URLReferto, URLUpdateStatusReferto, BODY_TYPE } from '../../../../core-trainer/assets/consts/api'
import { actGlobalFetch, getStatusCode } from '../../../../redux/api/actions';
import SimpleTable from '../../../../Components/Table/SimpleTable'
import { withSnackbar } from 'notistack';
import { columns, prepareDataForTable } from './columns'
import { Modal, Radio, Input, List } from 'antd';
import { ACTION_TYPE } from '../VariazioniGara/columns';
import { reverseHistory } from '../../../../utils/functions';

const ACTION_STATUS_TYPE_ITA = (email, params={target_delegation: {type: null, province: null, region: null}}) => ({
    CREATED: [`Il Club Manager `, <b>{email}</b>, ` ha `, <b>inserito</b> , ` questo referto partita`],
    APPROVED_FIGC: [`Il Segretario FIGC `, <b>{email}</b>, ` ha `, <b>approvato</b> , ` questo modulo`],
    REJECTED_FIGC: [`Il Segretario FIGC `, <b>{email}</b>, ` ha `, <b>rifiutato</b> , ` questo modulo`],
    APPROVED_OPPONENT: [`La richiesta di variazione gara è stata `, <b>approvata</b>, ` dal club avversario `, <b>{email}</b>],
    REJECTED_OPPONENT: [`La richiesta di variazione gara è stata `, <b>rifiutata</b>, ` dal club avversario `, <b>{email}</b>],
    SUBSTITUTED: [`Il Club Manager `, <b>{email}</b>, ` ha `, <b>aggiornato</b>, ` il modulo`],
    FORWARDED: [`Il segretario `, <b>{email}</b>, ` ha `, <b>inoltrato</b>, ` questa richiesta alla delegazione `, params.target_delegation.type === 'PRO' ? `provinciale di ${params.target_delegation.province}` : `regionale di ${params.target_delegation.region}`]
})

class ListMatch extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isDownloadingData: false,
            listRefertos: [],
            modalApproveRejectVisible: false,
            modalHistoryVisible: false,
            radioSelected: null,
            selectedRow: { status: {} },
            textInputValue: ""
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    resetModal = () => {
        this.setState({
            modalApproveRejectVisible: false,
            modalHistoryVisible: false,
            radioSelected: null,
            textInputValue: "",
            selectedRow: { status: {} },
        })
    }

    onClickModalCB = (selectedRow, actionType) => {
        if (actionType === ACTION_TYPE.APPROVE_REJECT) {
            this.setState({
                modalApproveRejectVisible: true,
                selectedRow: selectedRow,
                radioSelected: selectedRow.status.status,
                textInputValue: selectedRow.reject_motivation
            })
        } else if (actionType === ACTION_TYPE.HISTORY) {
            this.setState({
                modalHistoryVisible: true,
                selectedRow: selectedRow,
                selected_delegation: null
            })
        }
    }

    onConfirmApproveRejectModalButton = () => {
        var _listRefertos = this.state.listRefertos
        const _idReferto = this.state.selectedRow.id_referto
        const _radioSelected = this.state.radioSelected
        const _app_rej_date = `${new Date().getDate()}/${new Date().getMonth() + 1}/${new Date().getFullYear()}`
        const _textInputValue = _radioSelected === "REJECTED" ? this.state.textInputValue : null

        this.resetModal()
        this.props.actGlobalFetch(
            URLUpdateStatusReferto(_idReferto),
            SERVICES.BASE_MATCH,
            METHOD.PUT,
            true,
            BODY_TYPE.JSON,
            JSON.stringify({ status: _radioSelected, reject_motivation: _textInputValue, app_rej_date: _app_rej_date })
        ).then(response => {
            if (getStatusCode(response) === 200) {
                const index = _listRefertos.findIndex(obj => obj.id === _idReferto)
                if (_listRefertos[index].status === "WAITING") this.props.onStateChange()
                _listRefertos[index].status = _radioSelected
                _listRefertos[index].reject_motivation = _textInputValue
                _listRefertos[index].app_rej_date = _app_rej_date
                this.setState({ listRefertos: reverseHistory(_listRefertos) })
                this.props.enqueueSnackbar("Stato modificato", {
                    "data-cy": "snackbar_success",
                    variant: "success"
                });

            } else {
                this.showError()
            }
        }).catch((err) => {
            this.showError(err)
        })
    }
    onDiscardModalButton = () => {
        this.resetModal()
    }

    onTextInputChange = ({ target: { value } }) => { this.setState({ textInputValue: value }) }

    showError(e = null) {
        console.log("[ERROR]", e)
        this.props.enqueueSnackbar("Ops, qualcosa è andato storto", {
            variant: "error"
        });
    }

    fetchData = () => {
        this.setState({ isDownloadingData: true })
        this.props.actGlobalFetch(URLReferto(this.props.competition, this.props.round), SERVICES.BASE_MATCH, METHOD.GET, true, BODY_TYPE.EMPTY)
            .then(response => {
                if (getStatusCode(response) == 200) {
                    this.setState({ listRefertos: reverseHistory(response.data) })
                } else {
                    this.showError()
                }
                this.setState({ isDownloadingData: false })
            })
            .catch((err) => {
                this.showError(err)
                this.setState({ isDownloadingData: false })
            })
    }

    render() {
        const getTitle = () => `${this.state.selectedRow.match}`
        return (
            <div style={{ display: "flex", flex: 1 }}>
                <SimpleTable columns={columns(this.onClickModalCB)} dataSource={prepareDataForTable(this.state.listRefertos)} pagination={false} loading={this.state.isDownloadingData} size={"small"} headerSort={false} />
                {/* MODAL APPROVE-REJECT */}
                <Modal
                    title={getTitle()}
                    visible={this.state.modalApproveRejectVisible}
                    onOk={this.onConfirmApproveRejectModalButton}
                    onCancel={this.onDiscardModalButton}
                    okButtonProps={{ disabled: (this.state.radioSelected === this.state.selectedRow.status.status && this.state.textInputValue === this.state.selectedRow.reject_motivation) }}
                >
                    <Radio.Group onChange={(e) => { this.setState({ radioSelected: e.target.value }) }} value={this.state.radioSelected}>
                        <Radio data-cy={"radio_approva"} value={"APPROVED"}>{"Approva"}</Radio>
                        <Radio data-cy={"radio_rifiuta"} value={"REJECTED"}>{"Rifiuta"}</Radio>
                    </Radio.Group>
                    {this.state.radioSelected === "REJECTED" && <Input.TextArea style={{ marginTop: 10 }}
                        placeholder="Motivazione rifiuto"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        onChange={this.onTextInputChange}
                        value={this.state.textInputValue}
                        data-cy={"textarea_motivazione"}
                    />}
                </Modal>
                {/* MODAL HISTRY */}
                <Modal
                    title={getTitle()}
                    visible={this.state.modalHistoryVisible}
                    onOk={this.onDiscardModalButton}
                    onCancel={this.onDiscardModalButton}
                    zIndex={1}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    destroyOnClose
                >
                    <List dataSource={this.state.selectedRow.referto && this.state.selectedRow.referto.events_history}
                        renderItem={item => (
                            <List.Item>
                                <List.Item.Meta
                                    title={ACTION_STATUS_TYPE_ITA(item.who_made_email, item.params)[item.action_type]}
                                    description={new Date(Number(item.datetime)).toLocaleDateString()}
                                />
                            </List.Item>
                        )}
                    />
                </Modal>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    app: state.app,
    logged: state.logged
});

const mapActionsToProps = {
    actGlobalFetch,
};

export default connect(mapStateToProps, mapActionsToProps)(withSnackbar(ListMatch))